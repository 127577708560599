import { createRouter, createWebHashHistory } from "vue-router";

import LayoutBasic from "../layouts/LayoutBasic.vue";
import LayoutBlank from '../layouts/LayoutBlank.vue';
import LayoutHome from '../layouts/LayoutHome.vue';

import HomeView from "../views/HomeView.vue";

import drug from "./drug";
import edu from "./edu";
import expert from "./expert";
import food from "./food";

import my from "./my";
import my_dose from "./my_dose";
import my_drug from "./my_drug";
import my_notification from "./my_notification";
import notice from "./notice";
import user from "./user";


import analytics from "@/assets/js/analytics";
import ng from "@/assets/js/ng";
import storage from "@/assets/js/storage";



const routes = [
    {
        path: "/",
        name: "home",
        component: HomeView,
        meta: { layout: LayoutHome },
    },
    ...drug,
    ...edu,
    ...expert,
    ...food,
    ...my,
    ...my_dose,
    ...my_drug,
    ...my_notification,
    ...notice,
    ...user,
    {
        path: "/about",
        name: "about",
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
        meta: { layout: LayoutBasic, title: "테스트용" },
    },
    {
        path: "/help",
        name: "help",
        component: () => import("../views/HelpView.vue"),
        meta: { layout: LayoutBasic, title: "도움말" },
    },
    {
        path: "/loading",
        name: "loading",
        component: () => import("@/views/LoadingView.vue"),
        meta: { layout: LayoutBlank, title: "로딩" },
    },
    {
        path: "/my/qna",
        name: "MyQna",
        component: () => import("@/views/my/QnaListView.vue"),
        meta: { layout: LayoutBasic, title: "1:1 문의" },
    },
    {
        path: "/:catchAll(.*)",
        redirect: '/' // 리디렉션할 경로 설정
    }
];

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes,
});

const no_login = {
    "/": true,
    "/about": true,
    "/help": true,
};


export default {
    install(app) {
        router.install(app);

        router.beforeEach((to, from, next) => {
            console.log(from.path, "->", to.path);
            /*
            if(ng.history.list.length == 0) {
                ng.history.list.push(to);
                ng.history.back = to;
                router.push({path:'/loading'});
                return;
            }*/
        
            if (ng.modal.is_open) {
                next(false);
            } else {
                if (storage.is_login()) {
                    if (to.path.startsWith("/user")) {
                        router.replace("/");
                        return;
                    }
                } else {
                    if (!to.path.startsWith("/user") && no_login[to.path] == undefined) {
                        ng.swal.alert("로그인이 필요합니다.");
                        localStorage.setItem("return_url", to.fullPath);
                        router.replace("/user/login");
                        return;
                    }
                }
                analytics.log(get_info(from), get_info(to));
                next();
            }
        });
    },
};

function get_info(from) {
    return {
        path:from.path,
        query:from.query
    };
}





//export default router;