<template>
    <div class="TakeForm">

        <div style="width:100%;word-break: break-all;" v-if="test"> {{ form }}
            {{ medicine }}</div>

        <div class="form">
            <ASBox1 title="처방일" v-if="type != 3">
                <template v-slot:input>
                    <div class="input_a"><v-text-field class="v-text" v-model="form.p_date" placeholder="처방일을 선택해주세요"
                            variant="outlined" hide-details hide-no-data density="compact"
                            append-inner-icon="mdi-calendar-edit" @update:focused="v_date = true"
                            @click:appendInner="$emit('search')" readonly></v-text-field>
                    </div>
                </template>
            </ASBox1>

            <ASBox1 title="병원">
                <template v-slot:input>
                    <div class="input_b">
                        <div class="left">
                            <SimpleSelect v-model="hospital_sido" :items="sido" />
                        </div>
                        <div class="middle">
                            <SimpleSelect v-model="hospital_gugun" :items="gugun_h" />
                        </div>
                        <div class="right">
                            <ComboBox ref="hospital" v-model="hospital_input" placeholder="병원명을 입력해주세요."
                                :items="autocomplete_hospitals" :filter="customFilter" :clearable="true"
                                @select:item="select_hospital" @clear="autocomplete_hospitals = []"
                                @search="search_hospital">
                                <template v-slot:item="{ item }">
                                    <div class="hospital_select">
                                        <div class="head">{{ item.name }} </div>
                                        <div class="content">
                                            <p>{{ item.sido }} {{ item.gugun }} {{ item.emdong }}</p>
                                            <p>{{ item.phone }}</p>
                                        </div>
                                    </div>
                                </template>
                            </ComboBox>
                        </div>
                    </div>
                    <span class="hint">지역을 선택후 병원명을 2글자 이상 입력후 검색해주세요.</span>
                </template>
            </ASBox1>

            <ASBox1 title="약국">
                <template v-slot:input>
                    <div class="input_b">
                        <div class="left">
                            <SimpleSelect v-model="pharm_sido" :items="sido" />
                        </div>
                        <div class="middle">
                            <SimpleSelect v-model="pharm_gugun" :items="gugun_p" />
                        </div>
                        <div class="right">
                            <ComboBox ref="pharm" v-model="pharm_input" placeholder="약국명을 입력해주세요."
                                :items="autocomplete_pharms" :filter="customFilter" :clearable="true"
                                @select:item="select_pharm" @click:clear="autocomplete_pharms = []"
                                @search="search_pharm">
                                <template v-slot:item="{ item }">
                                    <div class="hospital_select">
                                        <div class="head">{{ item.name }} </div>
                                        <div class="content">
                                            <p>{{ item.sido }} {{ item.gugun }} {{ item.emdong }}</p>
                                            <p>{{ item.phone }}</p>
                                        </div>
                                    </div>
                                </template>
                            </ComboBox>
                        </div>
                    </div>
                    <span class="hint">지역을 선택후 약국명을 2글자 이상 입력후 검색해주세요.</span>
                </template>
            </ASBox1>

            <ASBox1 title="의약품 목록" v-if="type != 3">
                <template v-slot:input>
                    <div class="list_medicine">
                        <div class="medicine medicine2" v-for="(item, i) in this.form.drugs" :key="i">
                            <div class="info" @click="select_drug(item)">
                                <span class="item_name">{{ ng.clean_item_name(item.item_name) }}</span>
                                <span class="entp_name">{{ item.entp_name }}</span>
                                <span class="quantity">1회 {{ item.quantity }}알 | 1일 {{ item.rate }}회 | {{ item.days }}일
                                    <span v-if="item.usage != ''"> | {{ item.usage }}</span>
                                </span>
                            </div>
                            <div class="btn_del" @click="del_medicine(i)"><v-icon>mdi-delete</v-icon></div>
                        </div>

                        <div class="medicine no_medicine" v-if="this.form.drugs.length == 0" @click="v_medicine = true">
                            처방 의약품을 추가해주세요.
                        </div>
                        <div class="hint" v-if="this.form.drugs.length != 0">
                            의약품을 클릭하여 수정합니다.
                        </div>
                    </div>

                </template>
            </ASBox1>

            <ASBox1 title="의약품" v-if="type == 3">
                <template v-slot:input>
                    <div class="list_medicine" v-for="(item, i) in this.form.drugs" :key="i">
                        <div class="medicine">
                            <span class="item_name">{{ ng.clean_item_name(item.item_name) }}</span>
                            <span class="entp_name">{{ item.entp_name }}</span>
                        </div>
                        <div class="method">
                            <ASBox1 title="1일투여횟수">
                                <template v-slot:input>
                                    <div class="input_a"><v-text-field v-model="item.rate" suffix="회" type="number"
                                            variant="outlined" hide-details hide-no-data
                                            density="compact"></v-text-field>
                                    </div>
                                </template>
                            </ASBox1>
                            <ASBox1 title="1회투여량">
                                <template v-slot:input>
                                    <div class="input_a"><v-text-field v-model="item.quantity" suffix="알" type="number"
                                            variant="outlined" hide-details hide-no-data
                                            density="compact"></v-text-field>
                                    </div>
                                </template>
                            </ASBox1>
                            <ASBox1 title="총 투여일수">
                                <template v-slot:input>
                                    <div class="input_a"><v-text-field v-model="item.days" suffix="일" type="number"
                                            variant="outlined" hide-details hide-no-data
                                            density="compact"></v-text-field>
                                    </div>
                                </template>
                            </ASBox1>
                            <ASBox1 title="복용방법">
                                <template v-slot:input>
                                    <div class="input_a"><v-text-field v-model="item.usage" variant="outlined"
                                            hide-details hide-no-data density="compact"></v-text-field>
                                    </div>
                                </template>
                            </ASBox1>
                        </div>
                    </div>

                </template>
            </ASBox1>


            <hr />

            <ASBox1 title="폴더">
                <template v-slot:input>
                    <Folders v-model="form.folder_uuids" :all="false" @folders="set_folders"
                        @selectFolder="selectFolder" placeholder="저장할 폴더를 선택해주세요" multiple />
                </template>
            </ASBox1>

            <v-row gaps="1" class="main_buttons" v-if="type != 3">
                <v-col cols="6">
                    <ButtonComp @click="v_medicine = true">처방 의약품 추가</ButtonComp>
                </v-col>
                <v-col cols="6">
                    <ButtonComp class="p1" @click="save()" v-if="form.uuid == ''">등록</ButtonComp>
                    <ButtonComp class="p1" @click="save()" v-if="form.uuid != ''">저장</ButtonComp>
                </v-col>
            </v-row>

            <v-row v-if="type == 3">
                <v-col cols="12">
                    <ButtonComp class="p1" @click="save()">등록</ButtonComp>
                </v-col>
            </v-row>
        </div>


        <DialogComp v-model="v_medicine" title="처방 의약품 추가" fullscreen>
            <template v-slot:body>
                <div class="add_medicine">
                    <ASBox1 title="의약품명">
                        <template v-slot:input>
                            <ComboBox ref="medicine" v-model="medicine_input" placeholder="약품명을 두글자 이상 입력후 검색해주세요."
                                :items="autocomplete_medicines" :filter="customFilter" :clearable="true"
                                @select:item="select_medicine" @click:clear="autocomplete_medicines = []"
                                @search="search_medicine">
                                <template v-slot:item="{ item }">
                                    <div class="hospital_select">
                                        <div class="head">{{ ng.clean_item_name(item.item_name) }} </div>
                                        <div class="content">
                                            <p>{{ item.entp_name }}</p>
                                        </div>
                                    </div>
                                </template>
                            </ComboBox>
                        </template>
                    </ASBox1>

                    <div class="method">
                        <ASBox1 title="1일투여횟수">
                            <template v-slot:input>
                                <div class="input_a"><v-text-field v-model="medicine.rate" suffix="회" type="number"
                                        variant="outlined" hide-details hide-no-data density="compact"></v-text-field>
                                </div>
                            </template>
                        </ASBox1>
                        <ASBox1 title="1회투여량">
                            <template v-slot:input>
                                <div class="input_a"><v-text-field v-model="medicine.quantity" suffix="알" type="number"
                                        variant="outlined" hide-details hide-no-data density="compact"></v-text-field>
                                </div>
                            </template>
                        </ASBox1>
                        <ASBox1 title="총 투여일수">
                            <template v-slot:input>
                                <div class="input_a"><v-text-field v-model="medicine.days" suffix="일" type="number"
                                        variant="outlined" hide-details hide-no-data density="compact"></v-text-field>
                                </div>
                            </template>
                        </ASBox1>
                        <ASBox1 title="복용방법">
                            <template v-slot:input>
                                <div class="input_a"><v-text-field v-model="medicine.usage" variant="outlined"
                                        hide-details hide-no-data density="compact"></v-text-field>
                                </div>
                            </template>
                        </ASBox1>
                    </div>

                </div>
            </template>
            <template v-slot:actions>
                <v-row>
                    <v-col cols="6">
                        <ButtonComp class="p1" @click="add_ok()">확인</ButtonComp>
                    </v-col>
                    <v-col cols="6">
                        <ButtonComp @click="v_medicine = false">취소</ButtonComp>
                    </v-col>
                </v-row>
            </template>
        </DialogComp>


        <DialogComp2 v-model="v_date" :no_title="true" class="no_actions no_margin" width="360">
            <template v-slot:body>
                <DatePicker v-model="date_s" title="처방일" @select_date="select_date"></DatePicker>
            </template>
        </DialogComp2>


    </div>
</template>
<script>
import ASBox1 from '@/components/search/ASBox1.vue'
import ButtonComp from '@/components/ButtonComp.vue'
import ComboBox from '@/components/take/ComboBox.vue'
import DatePicker from '@/components/DatePicker.vue'
import DialogComp from '@/components/DialogComp.vue'
import DialogComp2 from '@/components/DialogComp2.vue'
import Folders from '@/components/drug/FolderComp.vue'
import SimpleSelect from '@/components/search/SimpleSelect.vue'
import moment from 'moment'

import axios from "axios";
import ng from '@/assets/js/ng'

export default {
    name: 'TakeForm',
    components: {
        ASBox1,
        ButtonComp,
        ComboBox,
        DatePicker,
        DialogComp,
        DialogComp2,
        Folders,
        SimpleSelect
    },
    props: {
        type: {
            type: Number,
            default: 0
        },
        item: {
            type: Object,
            default: null
        },
        new: {
            type: Boolean,
            default: false
        },
        test: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        customFilter() {
            return true;
        }
    },
    data: () => ({
        ng: ng,
        autocomplete_hospitals: [],
        autocomplete_pharms: [],
        autocomplete_medicines: [],
        folders: [],
        hospital_sido: '시도',
        hospital_gugun: '구군 전체',
        hospital_input: '',
        pharm_sido: '시도',
        pharm_gugun: '구군 전체',
        pharm_input: '',
        medicine_input: '',
        form: {
            folder_uuids: '',
            hospital: '',
            hospital_ykiho: '',
            pharm: '',
            pharm_ykiho: '',
            p_date: '',
            pu: '',
            drugs: [],
            qrcode: '',
            memo: '',
            uuid: ''
        },
        medicine: {
            edi_code: '',
            entp_name: '',
            item_image: '',
            item_name: '',
            item_seq: '',
            main_item_ingr: '',
            quantity: '',
            rate: '',
            days: '',
            usage: '',
            uuid: ''
        },
        sido: [
            "시도 전체",
            "강원",
            "경기",
            "경남",
            "경북",
            "광주",
            "대구",
            "대전",
            "부산",
            "서울",
            "세종시",
            "울산",
            "인천",
            "전남",
            "전북",
            "제주",
            "충남",
            "충북",
        ],
        gugun_h: ['구군 전체'],
        gugun_p: ['구군 전체'],
        date_s: '',
        timer_hospital: null,
        v_date: false,
        v_medicine: false,
    }),
    mounted() {
        if (this.item != null && !this.new) {

            this.copy_to(this.item, this.form, Object.keys(this.form))

            this.hospital_input = this.item.hospital;
            this.pharm_input = this.item.pharm;

            if (this.type == 1 || this.type == 2) {
                this.form.drugs = ng.clone(this.item.drugs)
            } else if (this.type == 3) {
                var keys = ['folder_uuids', 'hospital', 'hospital_ykiho', 'pharm', 'pharm_ykiho'];
                keys.forEach(key => {
                    this.form[key] = this.item[key] == undefined ? '' : this.item[key]
                });

                var keys2 = ['entp_name', 'item_image', 'item_name', 'item_seq', 'main_item_ingr', 'quantity', 'rate', 'days', 'usage'];
                var drug = {}
                keys2.forEach(key => {
                    drug[key] = this.item[key]
                });
                this.form.drugs = [drug];
            } else if (this.type == 4) {
                console.error(this.item)
            }
        } else {
            this.reset();
        }
    },
    methods: {
        add() {

        },
        copy_to(a, b, keys) {
            if (keys == undefined) keys = Object.keys(a);
            keys.forEach(key => {
                b[key] = a[key] == undefined ? '' : a[key];
            })
        },
        add_ok() {

            if (this.medicine.uuid == '') {
                try {
                    var db_dup = {};
                    this.form.drugs.forEach(m => {
                        db_dup[m.item_seq] = true;
                    });

                    if (db_dup[this.medicine.item_seq]) {
                        throw new Error(this.medicine.item_name + '이 중복등록되었습니다.');
                    }

                    this.check_medicine(this.medicine);
                    this.form.drugs.push(ng.clone(this.medicine));

                    this.medicine_input = '';

                    this.medicine.edi_code = '';
                    this.medicine.entp_name = '';
                    this.medicine.item_image = '';
                    this.medicine.item_seq = '';
                    this.medicine.item_name = '';
                    this.medicine.main_item_ingr = '';

                } catch (e) {
                    console.error(e);
                    this.$alert(e.message);
                }
            } else {
                this.form.drugs.forEach(d => {
                    if (d.uuid == this.medicine.uuid) {
                        this.copy_to(this.medicine, d)
                    }
                })
                this.v_medicine = false;
            }
        },
        check_medicine(m) {
            var keys = { 'item_name': '의약품을 선택해주세요.', 'quantity': '1회투여량을 입력해주세요.', 'rate': '1일투여횟수를 입력해주세요.', 'days': '투여일수를 입력해주세요.' }
            Object.keys(keys).forEach(key => {
                if (m[key] == '') {
                    throw new Error(keys[key])
                }
            })
            if (m.quantity == undefined || m.quantity == '' || m.quantity <= 0) {
                throw new Error(m.item_name + "의 1회 투여량은 0 이상이어야 합니다.");
            }
            if (m.rate == undefined || m.rate == '' || m.rate < 1) {
                throw new Error(m.item_name + "의 1일 투여횟수는 0 이상이어야 합니다.");
            }
            if (m.days == undefined || m.days == '' || m.days < 1) {
                throw new Error(m.item_name + "의 총 투약일수는 0 이상이어야 합니다.");
            }

            if (m.edi_code == undefined) m.edi_code = '';
            if (m.main_item_ingr == undefined) m.main_item_ingr = '';
        },
        dateFormat(a, format) {
            return moment(a).format(format);
        },
        del_medicine(i) {
            this.form.drugs.splice(i, 1);
        },
        reset() {
            this.form = {
                folder_uuids: '',
                hospital: '',
                hospital_ykiho: '',
                pharm: '',
                pharm_ykiho: '',
                p_date: '',
                pu: '',
                drugs: [],
                qrcode: '',
                memo: '',
                type: this.type,
                uuid: ''
            }
        },
        select_date(a) {
            this.form.p_date = a;
            this.v_date = false;
        },
        select_drug(d) {
            Object.keys(this.medicine).forEach(k => {
                this.medicine[k] = d[k];
            });
            this.medicine_input = d.item_name;

            this.v_medicine = true;
        },
        selectFolder(val) {
            this.form.folder_uuids = val;
        },
        select_hospital(a) {
            this.hospital_input = a.name;
            this.form.hospital = a.name;
            this.form.hospital_ykiho = a.ykiho;
            this.autocomplete_hospitals = [];
        },
        select_pharm(a) {
            this.pharm_input = a.name;
            this.form.pharm = a.name;
            this.form.pharm_ykiho = a.ykiho;
            this.autocomplete_pharms = [];
        },
        select_medicine(a) {
            var keys = ['edi_code', 'entp_name', 'item_name', 'item_seq', 'item_image', 'main_item_ingr'];
            keys.forEach(key => {
                this.medicine[key] = a[key];
            })
            this.medicine_input = a.item_name;
            this.autocomplete_medicines = [];
        },
        set_folders(val) {
            this.folders = val;

            if (this.form.uuid == '') {
                this.folders.forEach(folder => {
                    if (folder.de == 1) {
                        this.form.folder_uuids = folder.uuid;
                    }
                })
            }
        },
        search_hospital() {
            var val = this.hospital_input;
            var sido = this.hospital_sido != "시도" ? this.hospital_sido : "";
            var gugun = this.hospital_gugun != "구군 전체" ? this.hospital_gugun : "";
            var vm = this;

            if (sido == '') {
                this.$alert("지역을 선택해주세요.");
            } else if (val == null || val.length < 2) {
                this.autocomplete_hospitals = [];
                this.$alert("두글자 이상 입력해주세요.");
            } else {
                var url = `/api/organizations?type=병원&name=${val}&sido=${sido}&gugun=${gugun}`;
                axios({
                    url: url,
                }).then(function (response) {
                    var list = response.data.data;
                    list.forEach(item => {
                        if (item.name == val) {
                            item.point = 1;
                        } else {
                            item.point = 0;
                        }
                    });

                    list.sort(function (a, b) {
                        if (a.point > b.point) return -1;
                        else if (a.point < b.point) return 1;
                        else {
                            if (a.name < b.name) return -1;
                            else if (a.name > b.name) return 1;
                            else {
                                if (a.address < b.address) return -1;
                                else if (a.address > b.address) return 1;
                            }
                        }
                    });

                    vm.autocomplete_hospitals = response.data.data;
                    if (vm.autocomplete_hospitals.length == 0) {
                        vm.$alert('검색결과가 없습니다.')
                    }
                });
            }
        },
        search_pharm() {
            var val = this.pharm_input;
            var sido = this.pharm_sido != "시도" ? this.pharm_sido : "";
            var gugun = this.pharm_gugun != "구군 전체" ? this.pharm_gugun : "";
            var vm = this;

            if (sido == '') {
                this.$alert("지역을 선택해주세요.");
            } else if (val == null || val.length < 2) {
                this.autocomplete_phars = [];
                this.$alert("두글자 이상 입력해주세요.");
            } else {
                var url = `/api/organizations?type=약국&name=${val}&sido=${sido}&gugun=${gugun}`;
                axios({
                    url: url,
                }).then(function (response) {
                    var list = response.data.data;
                    list.forEach(item => {
                        if (item.name == val) {
                            item.point = 1;
                        } else {
                            item.point = 0;
                        }
                    });

                    list.sort(function (a, b) {
                        if (a.point > b.point) return -1;
                        else if (a.point < b.point) return 1;
                        else {
                            if (a.name < b.name) return -1;
                            else if (a.name > b.name) return 1;
                            else {
                                if (a.address < b.address) return -1;
                                else if (a.address > b.address) return 1;
                            }
                        }
                    });

                    vm.autocomplete_pharms = response.data.data;
                    if (vm.autocomplete_pharms.length == 0) {
                        vm.$alert('검색결과가 없습니다.')
                    }
                });
            }
        },
        search_medicine() {
            var val = this.medicine_input;
            if (val == null || val.length < 2) {
                this.$alert("두글자 이상 입력해주세요.");
                return;
            }
            var query = { page: 1 };
            query.search_text = val;
            query.mode = 'basic';
            query.order_name = 'item_name';
            query.order_direction = 'asc';

            this.$api.medicine.search({}, query).then((response) => {
                this.autocomplete_medicines = response.data.data;
                if (this.autocomplete_medicines.length == 0) {
                    this.$alert('검색결과가 없습니다.')
                }
            });
        },
        save() {
            try {
                if (this.type != 3) {
                    if (this.form.p_date == '') {
                        throw new Error('처방일을 등록해주세요.')
                    }
                }

                if (this.form.drugs.length == 0) {
                    throw new Error('한개이상의 의약품을 등록해주세요.')
                }
                var db_dup = {};
                this.form.drugs.forEach(m => {
                    this.check_medicine(m);
                    if (db_dup[m.item_seq]) {
                        throw new Error(m.item_name + '이 중복등록되었습니다.');
                    }
                    db_dup[m.item_seq] = true;
                });

                if (this.form.folder_uuids == '' || this.form.folder_uuids == undefined) {
                    throw new Error('폴더를 선택해주세요.');
                }

                if (this.type == 1 || this.type == 2) {

                    var data0 = {};

                    this.copy_to(this.form, data0);

                    data0.drugs = [];

                    this.form.drugs.forEach(d => {
                        var drug = {}
                        this.copy_to(d, drug, ['edi_code', 'entp_name', 'item_name', 'item_seq', 'item_image', 'main_item_ingr', 'quantity', 'rate', 'days', 'usage', 'uuid'])
                        data0.drugs.push(drug);
                    })

                    if (this.form.uuid == '') {
                        this.$confirm('처방이력을 등록할까요?').then(rs => {
                            if (rs.isConfirmed) {
                                this.$api.my.prescription.insert({}, {}, this.form).then(() => {
                                    this.$emit('saved');
                                });
                            }
                        });
                    } else {
                        this.$confirm('처방이력을 수정할까요?').then(rs => {
                            if (rs.isConfirmed) {
                                this.$api.my.prescription.update({ uuid: this.item.uuid }, {}, data0).then(() => {
                                    this.$emit('saved');
                                });
                            }
                        });
                    }
                } else if (this.type == 3) {

                    var data = {};
                    var keys = ['quantity', 'rate', 'days', 'usage', 'memo', 'folder_uuids', 'hospital', 'pharm', 'hospital_ykiho', 'pharm_ykiho'];
                    keys.forEach(key => {
                        data[key] = '';
                        if (this.form.drugs[0][key] != undefined) data[key] = this.form.drugs[0][key];
                        if (this.form[key] != undefined) data[key] = this.form[key];
                    });

                    if (typeof this.form.folder_uuids == 'string') {
                        this.form.folder_uuids = [this.form.folder_uuids];
                    }

                    if (this.form.uuid == undefined || this.form.uuid == '') {
                        this.$api.my.drug.takes.insert({}, {}, this.form).then(() => {
                            this.$emit('saved');
                        });
                    } else {
                        this.$api.my.drug.takes.update({ uuid: this.form.uuid }, {}, data).then(() => {
                            this.$emit('saved');
                        });
                    }
                } else if (this.type == 4) {

                    var data4 = {};

                    this.copy_to(this.form, data4);

                    data4.drugs = [];

                    this.form.drugs.forEach(d => {
                        var drug = {}
                        this.copy_to(d, drug, ['edi_code', 'entp_name', 'item_name', 'item_seq', 'item_image', 'main_item_ingr', 'quantity', 'rate', 'days', 'usage', 'uuid'])
                        data4.drugs.push(drug);
                    })

                    this.$api.my.drug.takes.insert({}, {}, data4).then(() => {
                        this.$emit('saved');
                    });
                } else {
                    this.$alert('잘못된 접근입니다.');
                }

                /*

                this.$api.my.drug.takes.insert({}, {}, this.form).then((response) => {
                    this.$alert('등록되었습니다.');
                });*/
            } catch (e) {
                this.$alert(e.message);
            }


        }
    },
    watch: {
        v_date(val) {
            if (val) {
                if (this.form.p_date != '') {
                    this.date_s = this.form.p_date;
                } else {
                    this.date_s = '';
                }
            }
        },
        v_medicine(val) {
            if (!val) {
                var keys = ['edi_code', 'entp_name', 'item_name', 'item_seq', 'item_image', 'main_item_ingr'];
                keys.forEach(key => {
                    this.medicine[key] = '';
                })
                this.medicine_input = '';
                this.autocomplete_medicines = [];
            }
        },
        hospital_sido(val) {
            this.gugun_h = ['구군 전체'],
                this.hospital_gugun = '구군 전체';
            this.$api.utils.sido.gugun({ sido: val }).then(response => {
                response.data.data.forEach(item => {
                    this.gugun_h.push(item.gugun);
                })
            })
        },
        pharm_sido(val) {
            this.gugun_h = ['구군 전체'],
                this.pharm_gugun = '구군 전체';
            this.$api.utils.sido.gugun({ sido: val }).then(response => {
                response.data.data.forEach(item => {
                    this.gugun_p.push(item.gugun);
                })
            })
        }
    }
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/_variables.scss";
@import "@/assets/css/_mixins.scss";

.v-select {
    @include select_de;
}

.TakeForm>*,
.add_medicine>* {
    margin-bottom: 20px !important;
}

.form {
    max-width: 500px;
    margin: 0px auto;

    >* {
        margin-bottom: 20px !important;
    }
}


hr {
    @include hr-line-gray;
}

.add_medicine {
    padding: 10px;
    background-color: white;
}

.medicine {
    width: 100%;
    border-radius: 8px;
    border: 2px solid $b-8;
    min-height: 48px;
    padding: 10px;
    margin-bottom: 5px;

    span {
        display: inline-block;
        margin-right: 5px;
    }

    span.quantity {
        font-size: 14px;
    }

    .btn_del {
        min-width: 24px;
        cursor: pointer;
        color: $b-g-5;
    }
}

.medicine2 {
    display: flex;

    .info {
        flex-grow: 1
    }
}


.no_medicine {
    text-align: center;
    font-size: 14px;
}



.input_a {
    width: 100%;
    @include search_append_inner;

    :deep(.v-text-field) {
        i {
            background-color: $b-9 !important;
        }
    }

    :deep(input::placeholder) {
        /*color: $b-9 !important;*/
        opacity: 1;
    }
}

.TakeForm {
    :deep(input::placeholder) {
        color: $b-g-4 !important;
        opacity: 1;
    }
}

.input_b {
    display: flex;
    flex-wrap: nowrap;
}

.input_b .left,
.input_b .middle {
    min-width: 120px;
    padding-right: 10px;
}

.input_b .right {
    flex-grow: 1;
}

span.hint {
    font-size: 12px;
}

.method {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .ASBox1 {
        width: 33%;
        padding: 5px;
    }

    .ASBox1:last-child {
        width: 100%;
        max-width: none;
    }

    .input_a .v-text-field:deep(input) {
        text-align: center;
        padding: 0px;
    }

    :deep(.ASBox1 .title) {
        text-align: center;
    }
}

@media (max-width: 450px) {

    .method {

        .ASBox1 {
            width: 33.3%;
            padding: 5px;
        }

        .ASBox1:last-child {
            width: 100%;
        }
    }

    .input_b {

        flex-wrap: wrap;

        .left,
        .middle {
            width: 50%;
        }

        .middle {
            padding-right: 0px;
        }

        .right {
            width: 100%;
            margin-top: 10px;
        }
    }
}




:deep(span.v-text-field__suffix) {
    height: 48px;

    span.v-text-field__suffix__text {
        color: #18338c !important;
        padding-right: 5px;
        font-size: 16px;
    }
}

.main_buttons .v-col:first-child .ButtonComp {
    padding-right: 5px;
}

.main_buttons .v-col:last-child .ButtonComp {
    padding-left: 5px;
}

.hospital_select {
    display: block;
    text-align: left;
    box-shadow: $shadow;
    padding: 0px;
    border-radius: 8px;
    margin: 5px 5px 10px 5px;
    overflow: hidden;


    .head {
        @include H4;
        padding: 0px 10px;
        width: 100%;
        line-height: 32px;
        background-color: $b-3;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .content {
        padding: 10px;
    }

    .content p {
        @include H5;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

div.hint {
    font-size: 12px;
}
</style>