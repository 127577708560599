export const message = {
    login_fail : '아이디 비밀번호가 일치하지 않습니다.',
    name_is_invalid : '이름은 2~20글자로 입력해주세요.',
    user_id_is_invalid : '유저아이디가 유효한 형식이 아닙니다.',
    email_is_invalid : '이메일이 유효한 형식이 아닙니다.',
    mobile_is_invalid : '휴대폰 번호가 유효한 형식이 아닙니다.',
    passwd_is_invalid : '비밀번호가 유효한 형식이 아닙니다.',
    auth_num_is_invalid : '인증번호가 유효한 형식이 아닙니다.',

    new_passwd_is_invalid : '비밀번호가 유효한 형식이 아닙니다.',

    need_to_confirm_email : "이메일 인증이 필요합니다.",
    need_to_login : "로그인이 필요합니다.",
    need_to_re_login : "로그인이 필요합니다.",

    user_id_is_dup : "유저 아이디가 중복입니다.",
    email_is_dup : "이메일이 중복입니다.",
    mobile_is_dup : "휴대폰번호가 중복입니다.",

    auth_num_is_mismatch : "인증번호가 일치하지 않습니다.",

    uuid_is_invalid : "유효한 접근이 아닙니다. (uuid)",
    type_is_invalid : "유효한 접근이 아닙니다.",
    password_mismatch : '비밀번호가 일치하지 않습니다.',

    add_drugs_is_invalid : "등록할 복용의약품정보가 유효한 형식이 아닙니다.",

    folder_is_invalid : '폴더가 유효하지 않습니다.',
};

export const wf = {
    "A02BC": "위산분비억제제",
    "A10": "당뇨약",
    "B01AA": "항응고제",
    "B01AB": "항응고제",
    "B01AC": "항혈소판제",
    "B01AD": "혈전용해제",
    "B01AE": "항응고제",
    "B01AF": "항응고제",
    "B01AX": "항응고제",
    "C01A": "부정맥치료제",
    "C01B": "부정맥치료제",
    "C02": "고혈압약",
    "C03": "고혈압약",
    "C04": "고혈압약",
    "C07": "고혈압약",
    "C08": "고혈압약",
    "C09": "고혈압약",
    "C10": "고지혈증약",
    "G03A": "에스트로겐 호르몬제",
    "G03C": "에스트로겐 호르몬제",
    "G03E": "에스트로겐 호르몬제",
    "G03F": "에스트로겐 호르몬제",
    "G03HB": "에스트로겐 호르몬제",
    "H01AB": "갑상선 호르몬제",
    "H03": "갑상선 호르몬제",
    "H05": "갑상선 호르몬제",
    "L04": "면역억제제",
    "L04AX03": "Methotrexate",
    "M01A": "비스테로이드성 항염증제",
    "N03": "항경련제",
    "N05B": "신경안정제",
    "N06A": "항우울증약",
    "R06": "항히스타민제"
};